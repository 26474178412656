code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.responsive-blog-footer {
  background: #4a4a4a;
  padding: 3rem 3rem;
  color: white;
}

@media screen and (max-width: 39.9375em) {
  .responsive-blog-footer h4 {
    font-size: 1.5rem;
  }
}

.responsive-blog-footer p {
  color: #8a8a8a;
}

.responsive-blog-footer .mailing-list {
  margin-bottom: 1.5rem;
}

.responsive-blog-footer .mailing-container {
  margin-bottom: 2rem;
}

.responsive-blog-footer .about-section, .responsive-blog-footer .tag-section {
  margin-bottom: 2rem;
}

.responsive-blog-footer .about-section a, .responsive-blog-footer .tag-section a {
  color: #1779ba;
}

.responsive-blog-footer .subscribe-button {
  background-color: #1779ba;
}

.responsive-blog-footer .subscribe-button:hover {
  background-color: #146aa3;
  transition: color 0.3s ease-in;
}

.responsive-blog-footer .fa-chevron-circle-up {
  font-size: 3rem;
  color: #8a8a8a;
}

.responsive-blog-footer .fa-chevron-circle-up:hover {
  color: #b0b0b0;
  transition: color 0.3s ease-in;
}

a.hyperlink-dark-bg {
  color: white;
}

a.hyperlink-dark-bg:hover {
  color: #b0b0b0;
}
a.hyperlink-light-bg {
  color: #b0b0b0;
}

a.hyperlink-light-bg:hover {
  color: white;
}